<template>
	<v-form v-if="!loading">
		<v-container>
			<v-row>
				<v-col cols="12">
					<h2 class="pa-0 mb-0 float-left">{{ name }}</h2>
					<v-btn @click="showSelectLocationModal" class="float-right"
						>Change</v-btn
					>
				</v-col>
			</v-row>
			<v-row>
				<v-col class="pa-0 ma-0" cols="8">
					<v-text-field
						height="45px"
						class="pa-0 ma-0"
						placeholder="Enter Query"
						outlined
						v-model="query"
					></v-text-field>
				</v-col>
				<v-col class="pa-0 ma-0" cols="4">
					<v-btn
						height="55px"
						class="ma-0"
						:disabled="disabled"
						x-large
						color="primary"
						@click="runQuery"
					>
						Run Query
					</v-btn>
				</v-col>
			</v-row>
			<v-row class="mb-5">
				<p class="ma-0 label mb-4">Radius/Area size:</p>
			</v-row>
			<v-row>
				<v-slider
					thumb-color="brown"
					step=".01"
					thumb-label="always"
					v-model="radius"
					:min="min"
					:max="max"
				>
					<template #prepend>{{ min }}KM</template>
					<template #append>{{ max }}KM</template></v-slider
				>
			</v-row>
			<v-row
				align="center"
				justify="center"
				v-if="
					selectedLocation &&
					selectedLocation.data &&
					selectedLocation.data.results
				"
			>
				<h2 class="float-left">Top Results</h2>
				<v-card
					v-for="(
						location, ind
					) in selectedLocation.data.results.slice(0, 3)"
					:key="ind"
					class="mb-5"
				>
					<v-card-text>
						<span class="float-right">
							<v-chip class="ma-2" color="red" text-color="white">
								Rank-{{ ind + 1 }}
							</v-chip>
						</span>
						<h2>{{ location.name }}</h2>

						<v-rating
							class="float-left"
							:value="location.rating"
							color="amber"
							dense
							half-increments
							readonly
							size="14"
						></v-rating
						><span class="float-left"
							>({{ location.user_ratings_total }})</span
						>
						<br />
						<div class="text--primary">
							{{ location.formatted_address }}
						</div>
					</v-card-text>
				</v-card>
				<v-btn @click="showResultsModal()" color="primary"
					>click to see full list</v-btn
				>
			</v-row>
			<v-row
				align="center"
				justify="center"
				v-if="localRanks && localRanks.length > 0 && !selectedLocation"
			>
				<h2>Click on a location to see results</h2>
			</v-row>
		</v-container>
	</v-form>
	<v-skeleton-loader
		v-else
		type="card-avatar,card-avatar,card-avatar"
	></v-skeleton-loader>
</template>


<script>
export default {
	data() {
		return {
			min: 1,
			max: 10,
		}
	},
	computed: {
		disabled() {
			if (this.query && this.query.length > 0) {
				return false
			}
			return true
		},
		localRanks: {
			get() {
				return this.$store.state.map.localRanks
			},
		},
		query: {
			get() {
				return this.$store.state.map.query
			},
			set(value) {
				this.$store.commit('map/updateQuery', value)
			},
		},
		radius: {
			get() {
				return this.$store.state.map.radius
			},
			set(value) {
				this.$store.commit('map/updateRadius', value)
			},
		},
		loading: {
			get() {
				return this.$store.state.map.loading
			},
		},
		selectedLocation: {
			get() {
				return this.$store.state.map.selectedLocation
			},
		},
		name: {
			get() {
				return this.$store.state.map.name
			},
		},
	},
	methods: {
		runQuery() {
			this.$emit('search', this.query)
		},
		showResultsModal() {
			this.$store.commit('map/showResultsModal', true)
		},
		showSelectLocationModal() {
			this.$store.commit('map/showSelectLocationModal', true)
		},
	},
}
</script>

<style scoped>
.size {
	font-size: 9px;
}
</style>