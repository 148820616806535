<template>
	<div>
		<LocalSearchRankerDesktop v-if="$vuetify.breakpoint.mdAndUp" />
		<LocalSearchRankerMobile v-if="!$vuetify.breakpoint.mdAndUp" />
	</div>
</template>

<script>
import LocalSearchRankerDesktop from './LocalSearchRankerDesktop'
import LocalSearchRankerMobile from './LocalSearchRankerMobile'
export default {
	components: {
		LocalSearchRankerDesktop,
		LocalSearchRankerMobile,
	},
}
</script>
