<template>
	<div>
		<div class="px-2" v-if="selectLocationModal">
			<v-row>
				<v-col cols="12" class="d-flex justify-center mb-6">
					<div class="d-flex flex-column align-center">
						<h2 class="text-h6 mb-4">
							Know where your business listing rank in the city for your most important keywords.
						</h2>
						<p class="text-subtitle-2">
							Get the data you need to prioritize the keywords that matter most for your business.
						</p>
					</div>
				</v-col>
				<v-spacer></v-spacer>
				<v-col cols="12" class="d-flex justify-center">
					<SelectLocation />
				</v-col>
			</v-row>
		</div>
		<Drawer class="px-2" v-if="!selectLocationModal" @search="runQuery" />
		<GoogleMap v-if="!selectLocationModal" />
		<ResultsModal />
	</div>
</template>

<script>
import GoogleMap from './components/mobile/GoogleMap';
import Drawer from './components/mobile/Drawer';
import ResultsModal from './components/mobile/ResultsModal';
import SelectLocation from './components/mobile/selectLocation';
export default {
	components: {
		GoogleMap,
		Drawer,
		ResultsModal,
		SelectLocation,
	},
	computed: {
		query: {
			get() {
				return this.$store.state.map.query;
			},
		},
		name: {
			get() {
				return this.$store.state.map.name;
			},
		},
		radius: {
			get() {
				return this.$store.state.map.radius;
			},
		},
		loading: {
			get() {
				return this.$store.state.map.loading;
			},
			set(value) {
				this.$store.commit('map/updateLoading', value);
			},
		},
		markers: {
			get() {
				return this.$store.state.map.markers;
			},
		},
		center: {
			get() {
				return this.$store.state.map.center;
			},
		},
		selectLocationModal: {
			get() {
				return this.$store.state.map.selectLocationModal;
			},
			set() {
				return this.$store.commit('map/showSelectLocationModal', false);
			},
		},
	},
	methods: {
		runQuery() {
			this.loading = true;
			const projectId = this.$store.state.app.projectId;

			const payload = {
				locations: [...this.markers, this.center],
				query: this.query,
				radius: this.radius * 1000,
				name: this.name,
			};
			this.axios({
				method: 'post',
				url: `/${projectId}/map/search`,
				data: payload,
			})
				.then((res) => {
					if (res.status === 404) {
						console.log(404);
					}
					if (res.data.success == true) {
						this.loading = false;
						this.$store.commit('map/updateRanks', res.data.localRanks);
					}
					// const data = res.data.data
					// this.setBlogData(data)
					// this.alert.message = res.data.message
					// }
				})
				.catch((error) => {
					let errors = error.response.data;
					this.alert.message = errors.message;
				});
		},
	},
};
</script>
