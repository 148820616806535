<template>
	<v-dialog
		v-model="showResultsModal"
		max-width="600px"
		v-if="showResultsModal"
	>
		<v-card class="pa-0 mb-0 d-flex justify-center">
			<v-card-title>Results for-"{{ query }}"</v-card-title>
		</v-card>

		<v-card
			class="pa-3 mb-0 d-flex flex-column"
			v-for="(location, ind) in selectedLocation.data.results"
			:key="ind"
		>
			<v-card-text>
				<span class="float-right">
					<v-chip class="ma-2" color="red" text-color="white">
						Rank-{{ ind + 1 }}
					</v-chip>
				</span>
				<h2>{{ location.name }}</h2>

				<v-rating
					class="float-left"
					:value="location.rating"
					color="amber"
					dense
					half-increments
					readonly
					size="14"
				></v-rating
				><span class="float-left"
					>({{ location.user_ratings_total }})</span
				>
				<br />

				<div class="text--primary">
					{{ location.formatted_address }}
				</div>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	computed: {
		selectedLocation: {
			get() {
				return this.$store.state.map.selectedLocation
			},
		},
		showResultsModal: {
			get() {
				return this.$store.state.map.showResultsModal
			},
			set() {
				return this.$store.commit('map/showResultsModal', false)
			},
		},
		query: {
			get() {
				return this.$store.state.map.query
			},
		},
	},
}
</script>
