<template>
	<v-dialog
		v-model="openModal"
		max-width="600"
		hide-overlay
		transition="dialog-bottom-transition"
	>
		<template v-slot:activator="{ on, attrs }">
			<div style="width: 100%">
				<p class="mb-2 text-subtitle-2 text-center">
					Search and Select Your Business Location
				</p>
				<v-btn color="primary" block dark v-bind="attrs" v-on="on">
					Search
				</v-btn>
			</div>
		</template>

		<v-card class="pa-3 mb-0 d-flex flex-column">
			<v-btn
				icon
				right
				class="d-flex justify-end"
				@click="openModal = false"
			>
				<v-icon>mdi-close</v-icon>
			</v-btn>
			<v-spacer></v-spacer>
			<GmapAutocomplete
				:position.sync="location"
				@place_changed="setPlace"
			>
			</GmapAutocomplete>
			<div class="pa-3 mb-0 d-flex flex-column">
				<GmapMap
					class="mb-5"
					:center="location"
					:zoom="selected ? 14 : 3"
					map-type-id="terrain"
					style="height: 300px"
				>
					<GmapMarker
						v-if="selected"
						:position="location"
						:draggable="true"
						@dragend="setCustomPlace($event.latLng)"
					/>
				</GmapMap>
				<v-btn
					@click="usePlace"
					:disabled="name ? false : true"
					color="primary"
					>Select</v-btn
				>
			</div>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	data() {
		return {
			openModal: false,
			selected: false,
			name: null,
			location: {
				lat: 19.034582,
				lng: 73.0654603,
			},
		}
	},
	computed: {
		center: {
			get() {
				return this.$store.state.map.center
			},
		},
		closeModal: {
			get() {
				if (this.center) {
					return false
				}
				return true
			},
		},
		selectLocationModal: {
			get() {
				return this.$store.state.map.selectLocationModal
			},
			set() {
				return this.$store.commit('map/showSelectLocationModal', false)
			},
		},
	},
	methods: {
		closeDialog() {
			this.$emit('closeModal')
		},
		setPlace(place) {
			if (place) {
				this.selected = true
				this.name = place.name
				this.location = {
					lat: place.geometry.location.lat(),
					lng: place.geometry.location.lng(),
				}
			}
		},
		setCustomPlace(coord) {
			this.location = {
				lat: coord.lat(),
				lng: coord.lng(),
			}
		},
		usePlace() {
			this.$store.commit('map/updateSearchedForLocation', {
				name: this.name,
				location: this.location,
			})
		},
	},
}
</script>
