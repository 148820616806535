<template>
	<div v-if="loading">
		<v-skeleton-loader type="card-avatar"></v-skeleton-loader>
	</div>
	<div v-else-if="localRanks && localRanks.length > 0">
		<GmapMap
			:center="center"
			:zoom="15"
			map-type-id="terrain"
			style="height: 800px"
		>
			<GmapMarker
				:position="center"
				:clickable="true"
				:draggable="false"
				ref="center"
				@dragend="updateCenter($event.latLng)"
				@click="setSelectedLocation(center)"
				:icon="setIcon(center)" />
			<GmapMarker
				:key="index"
				v-for="(m, index) in markers"
				:position="m"
				:clickable="true"
				@click="setSelectedLocation(m)"
				:icon="setIcon(m)" />
			<GmapCircle
				:center="center"
				:radius="radius * 1000"
				:visible="true"
				:options="{ fillColor: '#FF0000', fillOpacity: 0.2 }"
			></GmapCircle
		></GmapMap>
	</div>
	<div v-else>
		<GmapMap
			:center="center"
			:zoom="15"
			map-type-id="terrain"
			style="height: 800px"
		>
			<GmapMarker
				:position="center"
				:clickable="true"
				:draggable="false"
				ref="center"
				@dragend="updateCenter($event.latLng)"
				@click="setSelectedLocation(center)" />
			<GmapMarker
				:key="index"
				v-for="(m, index) in markers"
				:position="m"
				:clickable="true"
				@click="setSelectedLocation(m)" />
			<GmapCircle
				:center="center"
				:radius="radius * 1000"
				:visible="true"
				:options="{ fillColor: '#FF0000', fillOpacity: 0.2 }"
			></GmapCircle
		></GmapMap>
	</div>
</template>

<script>
export default {
	data() {
		return {
			markers: [],
		}
	},
	watch: {
		radius() {
			this.removeMarkers()
			this.createMarkers()
		},
	},
	computed: {
		center() {
			return this.$store.getters['map/getCenter']
		},
		loading() {
			return this.$store.getters['map/isLoading']
		},
		localRanks() {
			return this.$store.getters['map/getLocalRanks']
		},
		radius: {
			get() {
				return this.$store.state.map.radius
			},
		},
	},

	methods: {
		setIcon(location) {
			const obj = this.localRanks.find((object) => {
				return (
					object.location.lat === location.lat &&
					object.location.lng === location.lng
				)
			})
			const rank = obj.data.rank
			let url = null
			if (rank === '20+') {
				url = require('../../../assets/img/local-search-ranker/numbers/20plus.png')
				console.log('for 20plus', url)
			} else {
				url = require('../../../assets/img/local-search-ranker/numbers/' +
					rank +
					'.png')
				console.log('for less than 20', url)
			}
			return {
				url,
				scaledSize: { width: 40, height: 40 },
				labelOrigin: { x: 16, y: -10 },
			}
		},
		setSelectedLocation(location) {
			if (this.localRanks && this.localRanks.length > 0) {
				this.$store.commit('map/selectedLocation', location)
			}
		},
		updateCenter(evnt) {
			this.center = { lat: evnt.lat(), lng: evnt.lng() }
			this.removeMarkers()
			this.createMarkers()
		},
		toDegree(rad) {
			return (rad * 180) / Math.PI
		},
		toRadian(deg) {
			return (deg * Math.PI) / 180
		},
		destinationPoint(brng, dist) {
			dist = dist / 6371
			brng = this.toRadian(brng)

			let lat1 = this.toRadian(this.center.lat)
			let lon1 = this.toRadian(this.center.lng)

			let lat2 = Math.asin(
				Math.sin(lat1) * Math.cos(dist) +
					Math.cos(lat1) * Math.sin(dist) * Math.cos(brng)
			)
			var lon2 =
				lon1 +
				Math.atan2(
					Math.sin(brng) * Math.sin(dist) * Math.cos(lat1),
					Math.cos(dist) - Math.sin(lat1) * Math.sin(lat2)
				)

			if (isNaN(lat2) || isNaN(lon2)) return null

			return {
				lat: this.toDegree(lat2),
				lng: this.toDegree(lon2),
			}
		},
		createMarkers() {
			this.markers.push(this.destinationPoint(0, this.radius))
			this.markers.push(this.destinationPoint(45, this.radius))
			this.markers.push(this.destinationPoint(90, this.radius))
			this.markers.push(this.destinationPoint(135, this.radius))
			this.markers.push(this.destinationPoint(180, this.radius))
			this.markers.push(this.destinationPoint(225, this.radius))
			this.markers.push(this.destinationPoint(270, this.radius))
			this.markers.push(this.destinationPoint(315, this.radius))
			this.$store.commit('map/updateMarkers', this.markers)
		},
		removeMarkers() {
			this.markers = []
		},
	},
	created() {
		this.createMarkers()
	},
}
</script>
